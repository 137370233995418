<template>
    <div id="app">
        <div class="box w100">
            <a href="#"><img src="img/banner.jpg" width="100%"></a></div>
        <div class="box-item">
            <nut-row>
                <nut-col style="width: 60%">
                    <div class="flex-content">
                        <br/>
                    </div>
                </nut-col>
            </nut-row>
            <nut-row>
                <nut-col style="width: 60%">
                    <div class="flex-content">
                        <nut-textinput
                                :clearBtn="true"
                                :disabled="false"
                                maxlength="11"
                                placeholder="手机号码"
                                small="true"
                                v-model="mobile"
                        />
                    </div>
                </nut-col>
                <nut-col style="width: 40%;">
                    <div align="right" style="margin-top: 5px" >
                        <nut-button :disabled="disabled" @click="sendcode" small
                        >{{btntxt}}
                        </nut-button>
                    </div>
                </nut-col>
            </nut-row>
            <nut-row>
                <nut-col style="width: 60%">
                    <div class="flex-content">
                        <nut-textinput maxlength="4" placeholder="请输入收到的验证码" v-model="code"/>
                    </div>
                </nut-col>
            </nut-row>
            <nut-row>
                <nut-col>
                    <div class="checkbox">
                        <nut-checkbox v-model="dis">我已阅读并同意</nut-checkbox><nut-text onclick="window.location='/#/Agreement'"><a href="#">《用户协议与隐私条款》</a></nut-text>
                    </div>
                </nut-col>
            </nut-row>
            <nut-row>
                <nut-col>
                    <div align="center">
                        <nut-button @click="reg" block>注册</nut-button>
                    </div>
                </nut-col>
            </nut-row>
        </div>
    </div>
</template>

<script>
    import {sendCode, validCode} from '../api/getMyoldman'

    export default {
        name: "Reg",
        data() {
            return {
                mobile: "",
                code: "",
                disabled: false,
                time: 0,
                btntxt: "获取验证码",
                dis: false
            }
        },
        methods: {
            sendcode() {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.mobile == '') {
                    window.ts.Toast.text("请输入手机号码");
                } else if (!reg.test(this.mobile)) {
                    window.ts.Toast.text("手机格式不正确");
                } else {
                    this.time = 60;
                    this.disabled = true;
                    this.timer();
                    sendCode(this.mobile).then({})
                }
            },
            reg() {
                if (this.dis == false) {
                    window.ts.Toast.text("请勾选“我已阅读并同意《用户协议与隐私条款》”");
                }
                else {
                    validCode(this.code).then(res => {
                        if (res.data.code == 200) {
                            window.ts.Toast.text("注册成功！");
                            window.location.href = "/";
                        } else {
                            window.ts.Toast.text(res.data.message)
                        }
                    })}
            },
            timer() {
                if (this.time > 0) {
                    this.time--;
                    this.btntxt = this.time + "s后重新获取";
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 0;
                    this.btntxt = "获取验证码";
                    this.disabled = false;
                }
            }
        }
    }
</script>

<style scoped>
    .box-item {
        background: #fff;
        margin-bottom: 30px;
        border: 1px solid #ddd;
        padding: 20px 20px 10px;

    }
    .nut-col {
        margin-bottom: 20px;
    }
    .nut-button {
        background: #fc783a;
    }
</style>